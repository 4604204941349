// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

const Projects = () => {
  const [projectsData, setProjectsData] = useState([]);

  useEffect(() => {
    // Fetch the JSON file from the public/assets folder
    fetch("/assets/projects.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to load projects.json");
        }
        return response.json();
      })
      .then((data) => setProjectsData(data.projects))
      .catch((error) => console.error("Error loading projects:", error));
  }, []);

  const checkWeblink = (projectData) => {
    return projectData.weblink && projectData.weblink.length > 0;
  };

  const checkYoutubeLink = (projectData) => {
    return !projectData.youtubeLink.endsWith(".png");
  };

  const renderProject = (projectData) => (
    <div className="item" key={projectData.id}>
      <div className="text">
        <h1 className="bigTitle">{projectData.name}</h1>
        <p>
          <h3 className="title">Overview: </h3>
          <span className="exp">{projectData.description}</span>
          <h3 className="title">Problem Statement: </h3>
          <span className="exp">{projectData.problemStatement}</span>
          <br></br>
          <h3 className="title">Technologies Used:</h3>
          <span className="exp">{projectData.tool}</span>
          <h3 className="title">Key Achievement: </h3>
          <span className="exp">{projectData.keyAchievement}</span>
        </p>
      </div>
      <div className="showcase">
        {checkYoutubeLink(projectData) ? (
          <iframe
            src={`https://www.youtube.com/embed/${projectData.youtubeLink}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            title="Embedded YouTube"
            frameBorder="0"
            allowFullScreen
            style={{ width: "100%", height: "100%" }}
          ></iframe>
        ) : (
          <img src={projectData.youtubeLink} alt="Project Showcase" />
        )}
      </div>
      <div className="demo-button">
        {checkWeblink(projectData) ? (
          <Button href={projectData.weblink} target="_blank">
            Visit Code
          </Button>
        ) : (
          <Button
            disabled={true}
            style={{
              pointerEvents: "none",
              backgroundColor: "gray",
              border: "none",
            }}
          >
            Visible upon request
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <div className="wrapper">
      {projectsData.map(renderProject)}
    </div>
  );
};

export default Projects;
